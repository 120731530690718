import React from 'react'
import { Route, Switch } from 'react-router-dom'

import HomePage from '../../ui/pages/home-page'
import AboutPage from '../../ui/pages/about-page'
import Contact from '../../ui/pages/contact'
import Media from '../../ui/pages/media'
import TermsAndConditions from '../../ui/pages/terms-and-conditions'
import Privacy from '../../ui/pages/privacy'

const Main = location => {
  return (
    <Switch location={location.location}>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/media' component={Media} />
      <Route exact path='/about' component={AboutPage} />
      <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
      <Route exact path='/privacy' component={Privacy} />
      <Route exact path='/register' component={() => window.location.replace('https://forms.activitree.com/o/reg/FTqdJpLbgM2hD3gkt/web')} />
    </Switch>
  )
}

export default Main
