import React, { Component } from 'react'
import { FadeIn } from '../components/RenderWithEffects'
import $ from 'jquery'
import { TiWeatherSunny } from '../components/Icons/icons'

class AboutPage extends Component {
  componentDidMount () {
    $('html, body').animate({ scrollTop: 0 }, 200)
  }

  render () {
    return (
      <FadeIn>
        <div className='position-relative'>
          <section className='section bg-gradient-bubbles' style={{ paddingTop: '4rem' }}>
            <div className='container'>
              <div className='row text-center'>
                <div className='col-12'>
                  <h1 style={{ color: 'white', marginTop: '5rem', fontWeight: 300 }}>About us</h1>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <br />
                  <p>
                    The Stallions Academy was founded in 2012 by Cosmo, an ex-professional football player.
                    Cosmo is a very hands (or feet) on type of individual and is actively and directly involved in all activities
                    at the academy while he is also heading the Football division.
                  </p>
                  <p>
                    Cosmo and our Stallions team combine professionalism, expertise and love of all sports to build young leaders and to help them
                    balance mind and body. At the Stallions Academy children develop all essential sport skills and fair play.
                  </p>
                  <p>
                    All coaches are well known in the sports industry and their popularity continues to grow as do the schools,
                    nurseries and companies we work with, the new locations we are opening and the budding
                    footballers registering with our Academy.
                  </p>
                  <div className='text-center'>
                    <TiWeatherSunny style={{ fontSize: 36, color: '#f5365c', textAlign: 'center' }} />
                  </div>
                  <p>
                    We are passionate about sport and committed to providing high quality sports services, including football coaching, swim coaching, basketball,
                    league matches, professional development, personal training, corporate team building, one on
                    one private sessions, after school camps, holiday camps, birthday parties and much more. The
                    company ethos is to learn through having fun, progress and be the best you can be!
                  </p>
                  <p>The Stallions Academy specializes in providing first class football coaching for
                    children. We offer flexible programmes, with a choice of venues, dates and teaching methods
                    suitable for different age groups from beginners to advanced levels and we focus on achieving
                    individual personal goals and team development.
                  </p>
                  <div className='text-center'>
                    <TiWeatherSunny style={{ fontSize: 36, color: '#f5365c', textAlign: 'center' }} />
                    <br /><br /><br />
                    <b>We work hard to bringing out the best in each participant.</b>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br /><br /><br />
          <section>
            <div className='container container-lg'>
              <div className='row'>
                <div className='col-md-6 mb-5 mb-md-0'>
                  <div className='card card-lift--hover shadow border-0'>
                    <img src='https://assets.activitree.com/stallions/assets/stallions1.jpg' alt='football for kids' className='card-img' />
                  </div>
                </div>
                <div className='col-md-6 mb-5 mb-lg-0'>
                  <div className='card card-lift--hover shadow border-0'>
                    <img src='https://assets.activitree.com/stallions/assets/stallions2.jpg' alt='stallions football for kids' className='card-img' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </FadeIn>
    )
  }
}

export default AboutPage
