import React, { Component } from 'react'
import { TiPhoneOutline, TiMessages, TiKeyboard } from '../components/Icons/icons'
import { FadeIn } from '../components/RenderWithEffects'
import $ from 'jquery'

class Contact extends Component {
  componentDidMount () {
    $('html, body').animate({ scrollTop: $('.container-fluid').offset().top }, 200)
  }

  render () {
    return (
      <FadeIn>
        <div className='position-relative'>
          <section className='section bg-gradient-bubbles' style={{ paddingTop: '4rem' }}>
            <div className='container'>
              <div className='row text-center'>
                <div className='col-12'>
                  <h1 style={{ color: 'white', marginTop: '5rem', fontWeight: 300 }}>Let&apos;s get in touch</h1>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-12'>
                  <br />
                  <h2>For all queries:</h2>
                  <br /><br />
                  <div className='row' style={{ fontSize: 22 }}>
                    <div className='col-12 text-center'>
                      <TiPhoneOutline style={{ fontSize: 36, color: '#32325d' }} /> / <TiMessages style={{ fontSize: 36, color: '#32325d' }} /><br />
                      <a href='tel:+971529122262'>+971 52 912 2262</a>
                    </div>
                    <br /><br /><br />
                    <div className='col-12 text-center'>
                      <TiKeyboard style={{ fontSize: 36, color: '#32325d' }} /><br />
                      <a href='mailto:cosmo@stallionsacademy.com?Subject=Hello' target='_top' className='text-primary'>cosmo@stallionsacademy.com</a>
                      <br /><a href='mailto:info@stallionsacademy.com?Subject=Hello' target='_top' className='text-primary'>info@stallionsacademy.com</a>
                    </div>
                  </div>
                  <br /><br /><br />
                  <p>Address:</p>
                  <img src='https://assets.activitree.com/stallions/assets/logo120.png' alt='stallions academy logo' /><br />
                  Soccer Stallions Sports Academy LLC<br />
                  أكادمية سوكر ستالينس الرياضية <br />
                  P.O. Box 413178, Dubai, UAE<br />
                  <br /><br /><br />
                  <p className='description'>Fiscal:</p>
                  Registered with DED under license #729482<br />
                  TRN: 100482110200003
                </div>
              </div>
            </div>
          </section>
        </div>
      </FadeIn>
    )
  }
}

export default Contact
