import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { TiSocialFacebookCircular, TiSocialInstagram } from './Icons/icons'
import $ from 'jquery'

class Header extends Component {
  render () {
    return (
      <header className='header-global'>
        <nav id='navbar-main' className='navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom'>
          <div className='container'>
            <Link to='/' className='navbar-brand mr-lg-5'><img src='https://assets.activitree.com/stallions/assets/logow.png' alt='Stallions Academy logo white' /></Link>
            <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbar_global' aria-controls='navbar_global' aria-expanded='false' aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon' />
            </button>
            <div className='navbar-collapse collapse' id='navbar_global'>
              <div className='navbar-collapse-header'>
                <div className='row'>
                  <div className='col-6 collapse-brand'>
                    <Link to='/'><img src='https://assets.activitree.com/stallions/assets/logo120.png' alt='Stallions Academy logo' onClick={this.handleToggleCollapse} /></Link>
                  </div>
                  <div className='col-6 collapse-close'>
                    <button type='button' className='navbar-toggler' data-toggle='collapse' data-target='#navbar_global' aria-controls='navbar_global' aria-expanded='false' aria-label='Toggle navigation'>
                      <span />
                      <span />
                    </button>
                  </div>
                </div>
              </div>
              <ul className='navbar-nav navbar-nav-hover align-items-lg-center'>
                <li className='nav-item dropdown'>
                  <Link to='/about' className='nav-link' role='button' onClick={this.handleToggleCollapse}>
                    <span className='nav-link-inner--text'>About</span>
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <Link to='/contact' className='nav-link' role='button' onClick={this.handleToggleCollapse}>
                    <span className='nav-link-inner--text'>Contact</span>
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <Link to='/media' className='nav-link' role='button' onClick={this.handleToggleCollapse}>
                    <span className='nav-link-inner--text'>Media</span>
                  </Link>
                </li>
              </ul>
              <ul className='navbar-nav align-items-lg-center ml-lg-auto'>
                <li className='nav-item'>
                  <a className='nav-link nav-link-icon' href='https://www.facebook.com/StallionsSportsAcademy/' rel='noopener noreferrer' target='_blank' onClick={this.handleToggleCollapse}>
                    <TiSocialFacebookCircular style={{ fontSize: 36 }} />
                    <span className='nav-link-inner--text d-lg-none'>Facebook</span>
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link nav-link-icon' href='https://www.instagram.com/stallions.sports.academy/?hl=en' rel='noopener noreferrer' target='_blank' onClick={this.handleToggleCollapse}>
                    <TiSocialInstagram style={{ fontSize: 32 }} />
                    <span className='nav-link-inner--text d-lg-none'>Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }

  handleToggleCollapse () {
    if (window.innerWidth < 992) {
      $('.navbar').find('.navbar-toggler').click()
    }
  }
}

export default Header
