import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Main from '../../startup/client/routes'

import Header from '../components/header'
import Footer from '../components/footer'

class App extends Component {
  componentDidMount () {
    const { history } = this.props

    const j = document.createElement('script')
    j.setAttribute('type', 'text/javascript')
    j.setAttribute('src', 'https://code.jquery.com/jquery-3.4.1.min.js')
    document.getElementsByTagName('head')[0].appendChild(j)

    const bs = document.createElement('script')
    bs.setAttribute('type', 'text/javascript')
    bs.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js')
    document.getElementsByTagName('head')[0].appendChild(bs)

    // setTimeout(() => {
    // console.log(window.jQuery)
    // }, 200)

    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      import('../../libs/theme.js')
      import('react-ga')
        .then(ReactGA => {
          ReactGA.initialize('UA-109437854-2')
          ReactGA.set({ page: window.location.pathname })
          ReactGA.pageview(window.location.pathname)

          history.listen((location, action) => {
            console.log('location changed to:  ', location.pathname)
            ReactGA.set({ page: location.pathname })
            ReactGA.pageview(location.pathname)
          })
        })
    }, 1000)
  }

  render () {
    const { location } = this.props
    return (
      <div className='container-fluid' style={{ paddingLeft: '0', paddingRight: '0' }}>
        <Header />
        <div>
          <Main location={location} />
        </div>
        <Footer />
      </div>
    )
  }
}

export default App

App.propTypes = {
  children: PropTypes.object
}
