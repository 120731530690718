import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { TiSocialFacebook, TiSocialInstagram } from './Icons/icons'
import RegistrationModal from '../components/registration_modal'
import ReactGA from 'react-ga'

import device from 'device'

class Footer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      registerIsOpen: false,
      device: 'phone'
    }
    this.handleRegister = this.handleRegister.bind(this)
  }

  componentDidMount () {
    const myDevice = device(navigator.userAgent)
    this.setState({ device: myDevice.type })
  }

  render () {
    return (
      <footer className='footer has-cards'>
        <div className='container'>
          <div className='row row-grid align-items-center my-md'>
            <div className='col-lg-6'>
              <h3 className='text-primary font-weight-light mb-2'>Thank you for trusting our expertize!</h3>
              <h4 className='mb-0 font-weight-light'>Let&apos;s get in touch on any of these platforms.</h4>
            </div>
            <div className='col-lg-6 text-lg-center btn-wrapper'>
              <a target='_blank' href='https://www.facebook.com/StallionsSportsAcademy/' rel='noopener noreferrer' className='btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg' data-toggle='tooltip' data-original-title='Like us'>
                <TiSocialFacebook style={{ fontSize: 32 }} />
              </a>
              <a target='_blank' href='https://www.instagram.com/stallions.sports.academy/?hl=en' rel='noopener noreferrer' className='btn btn-neutral btn-icon-only btn-instagram btn-lg btn-round' data-toggle='tooltip' data-original-title='Follow us'>
                <TiSocialInstagram style={{ fontSize: 32 }} />
              </a>
            </div>
          </div>
          <hr />
          <div className='row align-items-center justify-content-md-between'>
            <div className='col-md-6'>
              <div className='copyright'>
                &copy;{new Date().getFullYear()},&nbsp; Soccer Stallion Sports Academy LLC
              </div>
            </div>
            <div className='col-md-6'>
              <ul className='nav nav-footer justify-content-end'>
                <li className='nav-item'>
                  <Link to='/terms-and-conditions' className='nav-link'>Terms</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/privacy' className='nav-link'>Privacy</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/contact' className='nav-link'>Contact</Link>
                </li>
                <li className='nav-item'>
                  <span style={{ cursor: 'pointer' }} onClick={this.handleRegister} className='nav-link'>Register</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <RegistrationModal registerIsOpen={this.state.registerIsOpen} onHandleRegister={this.handleRegister} />
      </footer>
    )
  }

  handleRegister (e) {
    e.preventDefault()
    const { device, registerIsOpen } = this.state
    if (device === 'phone') {
      window.location.replace('https://forms.activitree.com/o/reg/FTqdJpLbgM2hD3gkt/mobile')
      ReactGA.event({
        category: 'User',
        action: 'Start register from mobile'
      })
    } else if (this.state.device === 'desktop') {
      this.setState({ registerIsOpen: !registerIsOpen })
      ReactGA.event({
        category: 'User',
        action: 'Start register from desktop'
      })
    }
  }
}

export default Footer
