import React from 'react'

const IconContext = React.createContext && React.createContext({
  color: undefined,
  size: undefined,
  className: undefined,
  style: undefined,
  attr: undefined
})

export default IconContext
