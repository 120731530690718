import React, { Component } from 'react'
import { FadeIn } from '../components/RenderWithEffects'
import $ from 'jquery'

class Media extends Component {
  componentDidMount () {
    $('html, body').animate({ scrollTop: $('.container-fluid').offset().top }, 200)
  }

  render () {
    return (
      <FadeIn>
        <div className='position-relative'>
          <section className='section bg-gradient-bubbles' style={{ paddingTop: '4rem' }}>
            <div className='container'>
              <div className='row text-center'>
                <div className='col-12'>
                  <h1 style={{ color: 'white', marginTop: '5rem', fontWeight: 300 }}>Our Media Channels</h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </FadeIn>
    )
  }
}

export default Media
