import React, { Component } from 'react'
import { /* TiThListOutline, */ TiEdit } from '../components/Icons/icons'
import RegistrationModal from '../components/registration_modal'
// import FootballSchedules from './FootballSchedules'
import { FadeIn } from '../components/RenderWithEffects'
import $ from 'jquery'
import ReactGA from 'react-ga'

import device from 'device'
// import SummerCamp from './SummerCamp'

class HomePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      registerIsOpen: false,
      device: 'phone'
    }
    this.handleRegister = this.handleRegister.bind(this)
  }

  componentDidMount () {
    const myDevice = device(navigator.userAgent)
    this.setState({ device: myDevice.type })
  }

  render () {
    const height = window.innerWidth < 540 ? 'sm' : 'lg'
    return (
      <FadeIn>
        <main>
          <div className='position-relative'>
            <section className={`section section-${height} section-hero section-shaped`}>
              <div className={`shape shape-style-1 shape-primary-${height}`}>
                <span className='span-150' />
                <span className='span-50' />
                <span className='span-50' />
                <span className='span-75' />
                <span className='span-100' />
                <span className='span-75' />
                <span className='span-50' />
                <span className='span-100' />
                <span className='span-50' />
                <span className='span-100' />
              </div>
              <div className='container shape-container d-flex align-items-center' style={{ paddingTop: '3rem', paddingBottom: '8rem', width: '96%' }}>
                <div className='col px-0'>
                  <div className='row align-items-center justify-content-center'>
                    <div className='col-lg-6 text-center' style={{ padding: 10, background: 'rgba(0,0,0,0.4)', borderRadius: 32 }}>
                      <img src='https://assets.activitree.com/stallions/assets/logo240.png' style={{ width: 160, height: 160 }} alt='Stallions Football Academy Logo' />
                      <h1 style={{ fontSize: 28 }} className='text-white'>AMAZING SPORT SERVICES</h1>
                      <h2 className='lead text-white'>football, swimming, tennis, basketball, dance, yoga, referee services
                      </h2>
                      <div className='btn-wrapper mt-5'>
                        {/*
                        <span onClick={this.goToSchedule} className='btn btn-lg btn-white btn-icon mb-3 mb-sm-0'>
                          <span className='btn-inner--icon'><TiThListOutline style={{ fontSize: 20 }} /></span>
                          <span className='btn-inner--text'>Schedules</span>
                        </span>
                        */}
                        <button type='button' className='btn btn-lg btn-primary btn-icon mb-3 mb-sm-0' onClick={this.handleRegister}>
                          <span className='btn-inner--icon'><TiEdit style={{ fontSize: 24 }} /></span>
                          <span className='btn-inner--text' />
                          <span>Register</span>
                        </button>
                      </div>
                      <div className='mt-5'>
                        <small className='text-white font-weight mb-0 mr-2'>platformed with <span style={{ color: 'red' }}>&#10084;</span> by
                          <a style={{ color: 'white' }} href='https://www.activitree.com' rel='noopener noreferrer' target='_blank'> activitree.com</a>
                        </small>
                        <div style={{ background: '#333', borderRadius: '50%', width: 42, height: 42, display: 'inline-block', border: 'solid 1px white' }}>
                          <img src='https://assets.activitree.com/assets/icon-192x192.png' style={{ height: 40, borderRadius: '50%' }} alt='Activitree Logo' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='separator separator-bottom separator-skew zindex-100'>
                <svg x='0' y='0' viewBox='0 0 2560 100' preserveAspectRatio='none' version='1.1' xmlns='http://www.w3.org/2000/svg'>
                  <polygon className='fill-white' points='2560 0 2560 100 0 100' />
                </svg>
              </div>
            </section>

            {/*
            <FootballSchedules />
            */}

            <section className='section section-components pb-0' id='section-components'>
              <div className='container schedule'>
                <div className='row justify-content-center'>
                  <div className='col-12'>
                    <h2 style={{ textAlign: 'center', color: 'black' }}>Information and Contact</h2><br />
                    <h5>
                      For information and registrations by phone please call or Wapp:
                    </h5><br /><br />
                    <h2 style={{ textAlign: 'center' }}>
                      <a style={{ color: '#333' }} title='Call' href='tel:00971529122262'>052 912 2262</a>&nbsp;&nbsp;&nbsp;
                      <a href='https://wa.me/+971529122262 '>
                        <img alt='whatsapp' src='/wapp.png' style={{ width: 40, verticalAlign: 'text-top' }} />
                      </a>
                    </h2>

                    <br /><br />
                    <h5>
                      The Stallions Academy is committed to caring for the health and well-being of our students and partners and playing a constructive role
                      in supporting local health officials and government leaders.
                    </h5>
                    <br />
                    <h5>
                      All our activities are running in compliance with the governmental regulations for prevention of COVID-19. <b style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline' }} onClick={this.handleRegister}>Register</b> and receive our updates.
                    </h5>
                  </div>
                </div>
              </div>
            </section>
            <br /><br /><br /><br />

            <section className='section section section-shaped section-components'>
              <div className='shape shape-style-1 shape-default'>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <div className='container py-sm'>
                <div className='row justify-content-between align-items-center'>
                  <div className='col-12 col-md-4 text-center mt-3' style={{ color: 'white' }}>
                    <img src='https://assets.activitree.com/stallions/assets/cosmo.jpg' alt='Head Coach Cosmo' className='img-fluid rounded-circle shadow' style={{ width: 150, height: 150 }} />
                    <div className='card shadow card-blockquote' style={{ maxWidth: 360, marginTop: -44, zIndex: -1 }}>
                      <div className='card-body text-primary' style={{ paddingTop: 50 }}>
                        <p>Head Coach, Cosmo</p>
                        <cite>Ex international player, takes now roles from red-nosed cheeky-monkey for the youngest of the Stallions, to tactician for highly ranked teams in various championships in Dubai.</cite>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4 text-center mt-3' style={{ color: 'white' }}>
                    <img src='https://assets.activitree.com/stallions/assets/coach_lucio.jpg' alt='Coach Lucio' className='img-fluid rounded-circle shadow' style={{ width: 150, height: 150 }} />
                    <div className='card shadow card-blockquote' style={{ maxWidth: 360, marginTop: -44, zIndex: -1 }}>
                      <div className='card-body text-primary' style={{ paddingTop: 50 }}>
                        <p>Football Coach, Lucio</p>
                        <cite>Code name: 'dedication'. Special Mission: round up the mental and physical and teach children tenacity, strength and team spirit.</cite>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4 text-center mt-3' style={{ color: 'white' }}>
                    <img src='https://assets.activitree.com/stallions/assets/coach_carl.jpg' alt='Coach Carl' className='img-fluid rounded-circle shadow' style={{ width: 150, height: 150 }} />
                    <div className='card shadow card-blockquote' style={{ maxWidth: 360, marginTop: -44, zIndex: -1 }}>
                      <div className='card-body text-primary' style={{ paddingTop: 50 }}>
                        <p>Football Coach, Carl</p>
                        <cite>The Mrs. Doubtfire of the football department. His kindness and devotion cannot be matched on the next 5 continents. If Carl can't do it, nobody can do it.</cite>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <RegistrationModal registerIsOpen={this.state.registerIsOpen} onHandleRegister={this.handleRegister} />
        </main>
      </FadeIn>
    )
  }

  goToSchedule (e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: $('.schedule').offset().top }, 500)
  }

  handleRegister (e) {
    e.preventDefault()
    const { device, registerIsOpen } = this.state
    if (device === 'phone') {
      window.location.replace('https://forms.activitree.com/o/reg/FTqdJpLbgM2hD3gkt/mobile')
      ReactGA.event({
        category: 'User',
        action: 'Start register from mobile'
      })
    } else if (this.state.device === 'desktop') {
      this.setState({ registerIsOpen: !registerIsOpen })
      ReactGA.event({
        category: 'User',
        action: 'Start register from desktop'
      })
    }
  }
}

export default HomePage
