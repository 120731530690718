import { GenIcon } from './IconGenerator'

const TiLocationOutline = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M12 5c1.609 0 3.12.614 4.254 1.73 1.126 1.107 1.746 2.579 1.746 4.14s-.62 3.03-1.745 4.139l-4.255 4.184-4.254-4.186c-1.125-1.107-1.745-2.576-1.745-4.139s.62-3.032 1.745-4.141c1.135-1.113 2.647-1.727 4.254-1.727m0-2c-2.047 0-4.096.768-5.657 2.305-3.124 3.074-3.124 8.057 0 11.131l5.657 5.563 5.657-5.565c3.124-3.072 3.124-8.056 0-11.129-1.561-1.537-3.609-2.305-5.657-2.305zM12 8.499c.668 0 1.296.26 1.768.731.976.976.976 2.562 0 3.537-.473.472-1.1.731-1.768.731s-1.295-.26-1.768-.731c-.976-.976-.976-2.562 0-3.537.473-.471 1.101-.731 1.768-.731m0-1c-.896 0-1.792.342-2.475 1.024-1.367 1.367-1.367 3.584 0 4.951.684.684 1.578 1.024 2.475 1.024s1.792-.342 2.475-1.024c1.366-1.367 1.366-3.584 0-4.951-.683-.683-1.579-1.024-2.475-1.024z' }
    }]
  })(props)
}

const TiSocialInstagram = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M18 3h-12c-1.7 0-3 1.3-3 3v12c0 1.7 1.3 3 3 3h12c1.7 0 3-1.3 3-3v-12c0-1.7-1.3-3-3-3zm-6 6c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm3.8-2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2zm2.2 12h-12c-.6 0-1-.4-1-1v-6h2c0 2.8 2.2 5 5 5s5-2.2 5-5h2v6c0 .6-.4 1-1 1z' }
    }]
  })(props)
}

const TiMessages = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M21 7h-3c0-1.65-1.35-3-3-3h-12c-1.65 0-3 1.35-3 3v7c0 1.65 1.35 3 3 3v3l3-3c0 1.65 1.35 3 3 3h8l3 3v-3h1c1.65 0 3-1.35 3-3v-7c0-1.65-1.35-3-3-3zm-18 8c-.542 0-1-.458-1-1v-7c0-.542.458-1 1-1h12c.542 0 1 .458 1 1v1h-6.5c-1.379 0-2.5 1.121-2.5 2.5v4.5h-4zm19 2c0 .542-.458 1-1 1h-12c-.542 0-1-.458-1-1v-6.5c0-.827.673-1.5 1.5-1.5h11.5c.542 0 1 .458 1 1v7z' }
    }]
  })(props)
}

const TiEdit = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M21.561 5.318l-2.879-2.879c-.293-.293-.677-.439-1.061-.439-.385 0-.768.146-1.061.439l-3.56 3.561h-9c-.552 0-1 .447-1 1v13c0 .553.448 1 1 1h13c.552 0 1-.447 1-1v-9l3.561-3.561c.293-.293.439-.677.439-1.061s-.146-.767-.439-1.06zm-10.061 9.354l-2.172-2.172 6.293-6.293 2.172 2.172-6.293 6.293zm-2.561-1.339l1.756 1.728-1.695-.061-.061-1.667zm7.061 5.667h-11v-11h6l-3.18 3.18c-.293.293-.478.812-.629 1.289-.16.5-.191 1.056-.191 1.47v3.061h3.061c.414 0 1.108-.1 1.571-.29.464-.19.896-.347 1.188-.64l3.18-3.07v6zm2.5-11.328l-2.172-2.172 1.293-1.293 2.171 2.172-1.292 1.293z' }
    }]
  })(props)
}

const TiUserOutline = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M12 6c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3m0-2c-2.764 0-5 2.238-5 5s2.236 5 5 5 5-2.238 5-5-2.236-5-5-5zM12 17c2.021 0 3.301.771 3.783 1.445-.683.26-1.969.555-3.783.555-1.984 0-3.206-.305-3.818-.542.459-.715 1.777-1.458 3.818-1.458m0-2c-3.75 0-6 2-6 4 0 1 2.25 2 6 2 3.518 0 6-1 6-2 0-2-2.354-4-6-4z' }
    }]
  })(props)
}

const TiSocialFacebook = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M13 10h3v3h-3v7h-3v-7h-3v-3h3v-1.255c0-1.189.374-2.691 1.118-3.512.744-.823 1.673-1.233 2.786-1.233h2.096v3h-2.1c-.498 0-.9.402-.9.899v2.101z' }
    }]
  })(props)
}

const TiStopwatch = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'g',
      attr: {},
      child: [{
        tag: 'path',
        attr: { d: 'M19.414 8.902c.104-.048.206-.108.293-.195l.5-.5c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0l-.5.5-.115.173c-1.387-1.312-3.188-2.19-5.189-2.41l.011-.056v-1h1c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1s.45 1 1 1h1v1l.012.057c-4.506.492-8.012 4.307-8.012 8.943 0 4.971 4.029 9 9 9s9-4.029 9-9c0-1.894-.588-3.648-1.586-5.098zm-7.414 12.098c-3.859 0-7-3.14-7-7s3.141-7 7-7 7 3.14 7 7-3.141 7-7 7zM13 13v-2c0-.55-.45-1-1-1s-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1h-2zM12 8c-3.312 0-6 2.688-6 6s2.688 6 6 6 6-2.688 6-6-2.688-6-6-6zm0 11c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z' }
      }]
    }]
  })(props)
}

const TiThListOutline = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M19 18c.55 0 1 .45 1 1s-.45 1-1 1h-7c-.55 0-1-.45-1-1s.45-1 1-1h7m0-2h-7c-1.654 0-3 1.346-3 3s1.346 3 3 3h7c1.654 0 3-1.346 3-3s-1.346-3-3-3zM19 11c.55 0 1 .45 1 1s-.45 1-1 1h-7c-.55 0-1-.45-1-1s.45-1 1-1h7m0-2h-7c-1.654 0-3 1.346-3 3s1.346 3 3 3h7c1.654 0 3-1.346 3-3s-1.346-3-3-3zM19 4c.55 0 1 .45 1 1s-.45 1-1 1h-7c-.55 0-1-.45-1-1s.45-1 1-1h7m0-2h-7c-1.654 0-3 1.346-3 3s1.346 3 3 3h7c1.654 0 3-1.346 3-3s-1.346-3-3-3zM6 16h-2c-1.104 0-2 .896-2 2v2c0 1.104.896 2 2 2h2c1.104 0 2-.896 2-2v-2c0-1.104-.896-2-2-2zm0 4h-2v-2h2v2zM6 9h-2c-1.104 0-2 .896-2 2v2c0 1.104.896 2 2 2h2c1.104 0 2-.896 2-2v-2c0-1.104-.896-2-2-2zm0 4h-2v-2h2v2zM6 2h-2c-1.104 0-2 .896-2 2v2c0 1.104.896 2 2 2h2c1.104 0 2-.896 2-2v-2c0-1.104-.896-2-2-2zm0 4h-2v-2h2v2z' }
    }]
  })(props)
}

const TiSocialFacebookCircular = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M18.354 5.624c-1.75-1.741-3.888-2.624-6.354-2.624-2.489 0-4.633.884-6.373 2.625-1.743 1.741-2.627 3.887-2.627 6.375 0 2.465.883 4.603 2.624 6.354 1.741 1.756 3.886 2.646 6.376 2.646 2.467 0 4.605-.89 6.356-2.643 1.755-1.753 2.644-3.892 2.644-6.357 0-2.488-.89-4.634-2.646-6.376zm-1.412 11.319c-1.137 1.139-2.436 1.788-3.942 1.985v-4.928h2v-2h-2v-1.4c0-.331.269-.6.601-.6h1.399v-2h-1.397c-.742 0-1.361.273-1.857.822-.496.547-.746 1.215-.746 2.008v1.17h-2v2h2v4.93c-1.522-.195-2.826-.845-3.957-1.984-1.375-1.384-2.043-3.002-2.043-4.946 0-1.966.667-3.588 2.042-4.96 1.37-1.373 2.992-2.04 4.958-2.04 1.945 0 3.562.668 4.945 2.043 1.383 1.372 2.055 2.994 2.055 4.957 0 1.941-.673 3.559-2.058 4.943z' }
    }]
  })(props)
}

const TiWeatherSunny = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M13 4l-1 2.934-1-2.934c-.188-.553.106-1.152.659-1.341.552-.188 1.153.107 1.341.659.078.23.072.469 0 .682zM4 11l2.934 1-2.934 1c-.553.188-1.152-.106-1.341-.659-.188-.552.107-1.153.659-1.341.23-.078.469-.072.682 0zM11 20l1-2.934 1 2.934c.188.553-.106 1.152-.659 1.341-.552.188-1.152-.106-1.341-.659-.078-.23-.072-.469 0-.682zM20 12.998l-2.934-1 2.934-1c.553-.188 1.152.106 1.341.659.188.552-.106 1.152-.659 1.341-.23.078-.469.072-.682 0zM7.05 5.636l1.367 2.781-2.781-1.367c-.524-.257-.74-.891-.483-1.414.258-.523.891-.739 1.414-.482.218.107.383.28.483.482zM5.636 16.949l2.781-1.367-1.367 2.781c-.257.523-.891.739-1.414.482-.523-.258-.739-.891-.482-1.414.107-.218.28-.382.482-.482zM16.949 18.363l-1.367-2.781 2.781 1.367c.523.257.739.891.482 1.414-.258.523-.891.739-1.414.482-.218-.107-.382-.28-.482-.482zM18.362 7.048l-2.782 1.368 1.368-2.782c.257-.523.891-.739 1.414-.482.523.258.739.891.481 1.415-.106.217-.279.381-.481.481zM12 16.5c-2.481 0-4.5-2.019-4.5-4.5s2.019-4.5 4.5-4.5 4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zm0-7c-1.379 0-2.5 1.121-2.5 2.5s1.121 2.5 2.5 2.5 2.5-1.121 2.5-2.5-1.121-2.5-2.5-2.5z' }
    }]
  })(props)
}
const TiPhoneOutline = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M19.502 3.672l-1.795-1.793c-.566-.567-1.32-.879-2.121-.879s-1.555.312-2.121.879l-1.586 1.586c-1.17 1.17-1.17 3.072 0 4.242l1.379 1.379-4.172 4.172-1.379-1.379c-.566-.567-1.32-.879-2.121-.879s-1.555.312-2.121.879l-1.586 1.586c-1.17 1.17-1.17 3.072 0 4.242l1.794 1.793c.465.465 1.796 1.545 4.116 1.545 2.764 0 5.694-1.529 8.711-4.545 6.245-6.246 4.825-11.002 3.002-12.828zm-6.209 1.207l1.586-1.586c.195-.195.451-.293.707-.293s.512.098.707.293l1.083 1.082-3.001 3-1.082-1.082c-.391-.391-.391-1.023 0-1.414zm-10 11.414c-.391-.391-.391-1.023 0-1.414l1.586-1.586c.195-.195.451-.293.707-.293s.512.098.707.293l1.082 1.082-2.999 3-1.083-1.082zm11.793-1.207c-3.083 3.082-5.551 3.959-7.297 3.959-1.349 0-2.267-.523-2.702-.959-.004-.004 2.995-3.004 2.995-3.004l.297.297c.195.195.451.293.707.293s.512-.098.707-.293l5.586-5.586c.391-.391.391-1.023 0-1.414l-.297-.297 3.001-3c1.003 1.004 2.467 4.539-2.997 10.004z' }
    }]
  })(props)
}
const TiKeyboard = props => {
  return GenIcon({
    tag: 'svg',
    attr: { version: '1.2', baseProfile: 'tiny', viewBox: '0 0 24 24' },
    child: [{
      tag: 'path',
      attr: { d: 'M8 13h7v2h-7zM5 13h2v2h-2zM5 9h2v1h-2zM8 12v-1h-3v1h2zM8 9h1v1h-1zM9 11h1v1h-1zM10 9h1v1h-1zM11 11h1v1h-1zM12 9h1v1h-1zM13 11h1v1h-1zM14 9h1v1h-1zM15 11h1v1h-1zM16 9h1v1h-1zM17 12h2v-3h-1v2h-1zM18 13h-1v1h-1v1h3v-1h-1zM20 6h-16c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm0 10h-16v-8h16v8z' }
    }]
  })(props)
}

export {
  TiLocationOutline,
  TiSocialInstagram,
  TiMessages,
  TiEdit,
  TiUserOutline,
  TiSocialFacebook,
  TiStopwatch,
  TiThListOutline,
  TiSocialFacebookCircular,
  TiWeatherSunny,
  TiPhoneOutline,
  TiKeyboard
}
