import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FadeIn } from '../components/RenderWithEffects'
import $ from 'jquery'
import { TiWeatherSunny } from '../components/Icons/icons'

class TermsAndConditions extends Component {
  componentDidMount () {
    // $('html, body').animate({scrollTop: $('.terms').offset().top}, 200)
    $('html, body').animate({ scrollTop: 0 }, 200)
  }

  render () {
    return (
      <FadeIn>
        <div className='position-relative'>
          <section className='section bg-gradient-bubbles' style={{ paddingTop: '4rem' }}>
            <div className='container'>
              <div className='row text-center'>
                <div className='col-12'>
                  <h1 style={{ color: 'white', marginTop: '5rem', fontWeight: 300 }}>Terms and Conditions</h1>
                </div>
              </div>
            </div>
          </section>
          <section className='container'>
            <div className='row'>
              <div className='col-12'>
                <br />
                <h2>Website Terms and Conditions</h2>
                <p>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our <Link to='/privacy'>privacy policy</Link> govern Soccer Stallions Sports Academy’s relationship with you in relation to this website. <br /> <br />
                  The term “Soccer Stallions Sports Academy” or “us” or “we” refers to the owner of this website. The term “you” refers to the user or viewer of our website.<br /> <br /> The use of this website is subject to the following terms of use:
                </p>
                <ul>
                  <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                  <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                  <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                  <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                  <li>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</li>
                  <li>Unauthorised use of this website may give to a claim for damages and/or be a criminal offence.</li>
                  <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                  <li>You may not create a link to this website from another website or document without Soccer Stallions Sports Academy' prior written consent.</li>
                  <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England and Wales.</li>
                </ul>
              </div>
            </div>
          </section>
          <br /><br />
          <div className='text-center'>
            <TiWeatherSunny style={{ fontSize: 36, color: '#f5365c', textAlign: 'center' }} />
          </div>
          <br /><br />
          <section className='container'>
            <div className='row'>
              <div className='col-12'>
                <h2>Football classes Terms and Conditions</h2>
                <h4>Class Fees</h4>
                <p>
                  Fees will be charged on a pro rata basis<br />
                  Any sessions missed can be made up at any venue during that term (or will be forfeited)<br />
                  Termly payment is kindly requested in full at beginning of term<br />
                  There are 3 terms per academic year<br />
                  10% sibling discount on all sessions<br />
                  10% discount on twice weekly attendance<br />
                </p>
                <br /><br />
                <h2>Participation Disclaimer</h2>
                <p>
                  I consent to allow my son/daughter to participate in the Soccer Stallions Sports Academy
                  (SSSA) programme. I will not hold Soccer Stallions Sports Academy responsible for any
                  loss, damage or injury to the participant or his/her belongings or claim for any injury or
                  impairment the participant may incur during the Soccer Stallions Sports Academy Programme.
                  I also understand that Soccer Stallions Sports Academy reserve the right to make any necessary
                  changes to the programme prior to or during the course.  I give permission for any photographs
                  taken of my child during the Soccer Stallions Sports Academy programme to be used for promotional purposes.
                </p>
              </div>
            </div>
          </section>
        </div>
      </FadeIn>
    )
  }
}

export default TermsAndConditions
