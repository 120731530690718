/* globals $ */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

class FadeIn extends Component {
  componentDidMount () {
    const that = this
    /* eslint-disable-next-line */
    const elem = ReactDOM.findDOMNode(that)
    elem.style.opacity = 0.6
    // elem.classList.add('do-not-show')
    setTimeout(() => {
      elem.classList.add('fade-in')
    }, 0)
  }

  render () {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

class FadeInWithScrollUp extends Component {
  componentDidMount () {
    $('html, body').animate({ scrollTop: $('body').offset().top }, 700)
    const that = this
    /* eslint-disable-next-line */
    const elem = ReactDOM.findDOMNode(that)
    elem.style.opacity = 0
    // elem.classList.add('do-not-show')
    setTimeout(() => {
      elem.classList.add('fade-in')
    }, 0)

    /*
    window.requestAnimationFrame(() => {
      elem.style.transition = that.props.transition || 'opacity 1000ms'
      elem.style.opacity = 1
    })
    */
  }

  render () {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

export { FadeIn, FadeInWithScrollUp }
