/* eslint-disable */
import React from 'react'
import iconContext_1 from './iconContext'

let __assign = (this && this.__assign) || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i]
      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) {
          t[p] = s[p]
        }
      }
    }
    return t
  }
  return __assign.apply(this, arguments)
}
let __rest = (this && this.__rest) || function (s, e) {
  var t = {}
  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) { t[p] = s[p] }
  }
  if (s != null && typeof Object.getOwnPropertySymbols === 'function') {
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) { t[p[i]] = s[p[i]] }
  }
  return t
}
// Object.defineProperty(exports, '__esModule', { value: true })
const Tree2Element = tree => {
  return tree && tree.map(function (node, i) { return React.createElement(node.tag, __assign({ key: i }, node.attr), Tree2Element(node.child)) })
}
const GenIcon = data => {
  return function (props) { return (React.createElement(IconBase, __assign({ attr: __assign({}, data.attr) }, props), Tree2Element(data.child))) }
}

const IconBase = props => {
  const elem = conf => {
    const computedSize = (props && props.size) || (conf && conf.size) || '1em'
    let className
    if (conf && conf.className) { className = conf.className }
    if (props.className) { className = (className ? className + ' ' : '') + props.className }
    let attr = props.attr; let svgProps = __rest(props, ['attr'])
    return (React.createElement('svg', __assign({ stroke: 'currentColor', fill: 'currentColor', strokeWidth: '0' }, conf && conf.attr, attr, svgProps, { className: className, style: __assign({ color: props.color || conf && conf.color }, conf && conf.style, props.style), height: computedSize, width: computedSize }), props.children))
  }
  return iconContext_1.IconContext !== undefined
    ? React.createElement(iconContext_1.IconContext.Consumer, null, function (conf) { return elem(conf) })
    : elem(iconContext_1.DefaultContext)
}

export { GenIcon, IconBase }
/* eslint-enable */
