import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

class RegistrationModal extends Component {
  render () {
    const { registerIsOpen, onHandleRegister } = this.props
    const height = window.innerWidth < 540 ? '80vh' : '70vh'
    return (
      <Modal isOpen={registerIsOpen} toggle={onHandleRegister} className='registrationModal'>
        <ModalHeader toggle={onHandleRegister}>Player Registration</ModalHeader>
        <ModalBody>
          <iframe id='register' title='registration' style={{ width: '100%', height }} src='https://forms.activitree.com/o/reg/FTqdJpLbgM2hD3gkt/web' />
        </ModalBody>
      </Modal>
    )
  }
}

export default RegistrationModal
