import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FadeIn } from '../components/RenderWithEffects'
import $ from 'jquery'

class Privacy extends Component {
  componentDidMount () {
    $('html, body').animate({ scrollTop: 0 }, 200)
  }

  render () {
    return (
      <FadeIn>
        <div className='position-relative'>
          <section className='section bg-gradient-bubbles' style={{ paddingTop: '4rem' }}>
            <div className='container'>
              <div className='row text-center'>
                <div className='col-12'>
                  <h1 style={{ color: 'white', marginTop: '5rem', fontWeight: 300 }}>Privacy and Cookies</h1>
                </div>
              </div>
            </div>
          </section>
          <section className='container'>
            <div className='row'>
              <div className='col-12'>
                <br />
                <p>At <strong>Soccer Stallions Sports Academy</strong> we are dedicated to safeguarding and preserving your privacy when visiting our
                  site or communicating electronically with us. <br /> <br />
                  This Privacy Policy, together with our terms of use, explains what happens to any personal data
                  that you provide to us, or that we collect from you when you are on this site. <br />
                  <br /> We do update this Policy from time to time so please do return and review this Policy regularly.
                </p>
                <p>In operating our website we may collect and process the following data about you:</p>
                <ol>
                  <li>Details of your visits to our website and the resources that you access, including, but not limited to, traffic data, location data, weblogs and other communication data.</li>
                  <li>Information that you provide by filling in forms on our website, such as when you registered for information or make a purchase.</li>
                  <li>Information provided to us when you communicate with us for any reason.</li>
                </ol>
                <h2 style={{ paddingTop: 12 }}>How we use cookies</h2>
                <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                <p>Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
                <h3>To enable/disable cookies</h3>
                <p>If you're not sure of the type and version of web browser you use to access the Internet:</p>
                <p><strong>For PCs:</strong> click on 'Help' at the top of your browser window and select the 'About' option<br /> <strong>For Macs:</strong> with the browser window open, click on the Apple menu and select the 'About' option</p>

                <h2>Use of Your Information</h2>
                <p>The information that we collect and store relating to you is primarily used to enable us to provide our services to you. In addition, we may use the information for the following purposes:</p>
                <ol>
                  <li>To provide you with information requested from us, relating to our products or services. To provide information on other products which we feel may be of interest to you, where you have consented to receive such information.</li>
                  <li>To meet our contractual commitments to you.</li>
                  <li>To notify you about any changes to our website, such as improvements or service/product changes, that may affect our service.</li>
                </ol>
                <p>If you are an existing customer, we may contact you with information about goods and services similar to those which were the subject of a previous sale to you. <br /> Further, we may use your data, or permit selected third parties to use your data, so that you can be provided with information about unrelated goods and services which we consider may be of interest to you. We or they may contact you about these goods and services by any of the methods that you consented at the time your information was collected.<br /> If you are a new customer, we will only contact you or allow third parties to contact you only when you have provided consent and only by those means you provided consent for. <br /> If you do not want us to use your data for our or third parties you will have the opportunity to withhold your consent to this when you provide your details to us on the form on which we collect your data.<br /> <br /> Please be advised that we do not reveal information about identifiable individuals to our advertisers but we may, on occasion, provide them with aggregate statistical information about our visitors.</p>
                <h2>Storing Your Personal Data</h2>
                <p>We may transfer data that we collect from you to locations outside of the European Economic Area for processing and storing. Also, it may be processed by staff operating outside the European Economic Area who work for us or for one of our suppliers. For example, such staff maybe engaged in the processing and concluding of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all reasonable steps to make sure that your data is treated securely and in agreement with this Privacy Policy.<br /> <br /> Data that is provided to us is stored on our secure servers. Details relating to any transactions entered into on our site will be encrypted to ensure its safety. <br /> <br /> The transmission of information via the internet is not completely secure and therefore we cannot guarantee the security of data sent to us electronically and transmission of such data is therefore entirely at your own risk. Where we have given you (or where you have chosen) a password so that you can access certain parts of our site, you are responsible for keeping this password confidential.</p>
                <h3>Disclosing Your Information</h3>
                <p>Where applicable, we may disclose your personal information to any member of our group. This includes, where applicable, our subsidiaries, our holding company and its other subsidiaries (if any).<br /> <br /> We may also disclose your personal information to third parties:</p>
                <ol>
                  <li>Where we sell any or all of our business and/or our assets to a third party.</li>
                  <li>Where we are legally required to disclose your information.</li>
                  <li>To assist fraud protection and minimise credit risk.</li>
                </ol>
                <h2>Third Party Links</h2>
                <p>You mind find links to third party websites on our website. These websites should have their own privacy policies which you should check. We do not accept any responsibility or liability for their policies whatsoever as we have no control over them.</p>
                <h2>Access To Information</h2>
                <p>The Data Protection Act 1998 gives you the right to access the information that we hold about you. Please note that any demand for access may be subject to payment of a fee of £10 which covers our costs in providing you with the information requested. Should you wish to receive details that we hold about you please contact us using the contact details below.</p>
                <h2>Contacting Us</h2>
                <p>We welcome any queries, comments or requests you may have regarding this Privacy Policy. Please do not hesitate to contact us at by
                  <Link to='/contact'> clicking here</Link>
                </p>
              </div>
            </div>
          </section>
        </div>
      </FadeIn>
    )
  }
}

export default Privacy
